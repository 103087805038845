import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { TrendingUp } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { CartesianGrid, Line, LineChart, XAxis, Pie, PieChart, Bar, BarChart, LabelList } from 'recharts';
import { useGetReferralByIdQuery, useGetReferralStatsQuery, useGetSalesOverTimeQuery } from '@/services/referralApi';
import ReferralLinkButton from '@/components/Referral/ReferralLinkButton';
import { CenteredSpinner } from '@/components/ui/spinner';
import type { LabbError } from '@/types/Error';
import { formatPrice } from '@/lib/utils';
import { PageHeader } from '@/components/PageHeader';
import { type ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart';
import { DataTable } from '@/components/dataTable/DataTable';
import { type ColumnDef, type ColumnFiltersState, type PaginationState } from '@tanstack/react-table';

interface ProcessedSalesData {
    date: string;
    [key: string]: string | number;
}

interface PieChartData {
    name: string;
    value: number;
    fill: string;
}

interface ReferralStatsData {
    userId: string;
    userName: string;
    totalTickets: number;
    totalRevenue: number;
}

interface MonthlyChartData {
    month: string;
    tickets: number;
}

const generatePastelColor = (index: number): string => {
    const hue = (index * 137.5) % 360;
    return `hsl(${hue}, 50%, 75%)`;
};

const ReferralDetailPage: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10,
    });
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const { data: referralData, isLoading, error: referralError } = useGetReferralByIdQuery(id ?? '');
    const { data: statsData, error: statsError } = useGetReferralStatsQuery(id ?? '');
    const { data: salesData, error: salesError } = useGetSalesOverTimeQuery(id ?? '');

    const isUnauthorized = [referralError, statsError, salesError].some(
        (error) => (error as LabbError)?.data?.status === 401,
    );

    if (isLoading) {
        return <CenteredSpinner />;
    }
    if (!id) {
        return <div>Invalid referral id</div>;
    }
    if (isUnauthorized) {
        return <div>You are not allowed to visit this page.</div>;
    }
    if (!referralData) {
        return <div>Referral not found</div>;
    }

    const processedSalesData: ProcessedSalesData[] = (() => {
        if (!salesData) {
            return [];
        }

        let earliestDate = new Date();
        let latestDate = new Date(0);
        const userSet = new Set<string>();

        salesData.forEach((user) => {
            userSet.add(user.userName);
            user.salesData.forEach((sale) => {
                const saleDate = new Date(sale.date);
                if (saleDate < earliestDate) {
                    earliestDate = saleDate;
                }
                if (saleDate > latestDate) {
                    latestDate = saleDate;
                }
            });
        });

        const dateArray: string[] = [];
        for (let d = new Date(earliestDate); d <= latestDate; d.setDate(d.getDate() + 1)) {
            dateArray.push(d.toISOString().split('T')[0] ?? '');
        }

        const result: ProcessedSalesData[] = dateArray.map((date) => {
            const entry: ProcessedSalesData = { date };
            userSet.forEach((user) => {
                entry[user] = 0;
            });
            return entry;
        });

        salesData.forEach((user) => {
            user.salesData.forEach((sale) => {
                const index = result.findIndex((item) => item.date === sale.date);
                if (index !== -1) {
                    result[index] = {
                        ...result[index],
                        date: result[index]?.date ?? sale.date,
                        [user.userName]: ((result[index]?.[user.userName] as number) ?? 0) + sale.ticketsSold,
                    };
                }
            });
        });

        return result;
    })();

    const processMonthlyData = (): MonthlyChartData[] => {
        const monthlyData: { [key: string]: number } = {};
        salesData?.forEach((user) => {
            user.salesData.forEach((sale) => {
                const monthYear = new Date(sale.date).toLocaleString('default', { month: 'short', year: 'numeric' });
                monthlyData[monthYear] = (monthlyData[monthYear] || 0) + sale.ticketsSold;
            });
        });
        return Object.entries(monthlyData)
            .map(([month, tickets]) => ({ month, tickets }))
            .sort((a, b) => new Date(a.month).getTime() - new Date(b.month).getTime());
    };

    const monthlyChartData = processMonthlyData();

    const chartConfig: ChartConfig =
        salesData?.reduce(
            (acc, user, index) => ({
                ...acc,
                [user.userName]: {
                    label: user.userName,
                    color: generatePastelColor(index),
                },
            }),
            {},
        ) ?? {};

    const pieChartData: PieChartData[] =
        statsData?.map((stat, index) => ({
            name: stat.userName,
            value: stat.totalTickets,
            fill: generatePastelColor(index),
        })) ?? [];

    const totalRevenue = statsData?.reduce((sum, stat) => sum + stat.totalRevenue, 0) ?? 0;
    const totalTickets = statsData?.reduce((sum, stat) => sum + stat.totalTickets, 0) ?? 0;

    const columns: ColumnDef<ReferralStatsData>[] = [
        {
            accessorKey: 'userName',
            header: 'Name',
        },
        {
            accessorKey: 'totalTickets',
            header: 'Total Tickets Sold',
        },
        {
            accessorKey: 'totalRevenue',
            header: 'Total Revenue',
            cell: ({ row }) => formatPrice(row.original.totalRevenue),
        },
    ];

    const tableData = {
        data: statsData ?? [],
        totalElements: statsData?.length ?? 0,
        totalPages: 1,
        pageNumber: pagination.pageIndex,
        pageSize: pagination.pageSize,
    };

    return (
        <div className="space-y-6 p-6">
            <PageHeader>
                <h2 className="text-3xl font-bold">{referralData.title}</h2>
                <ReferralLinkButton campaign={referralData} />
            </PageHeader>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                <Card>
                    <CardHeader>
                        <CardTitle>Daily Ticket Sales</CardTitle>
                        <CardDescription>
                            {processedSalesData[0]?.date} - {processedSalesData[processedSalesData.length - 1]?.date}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="flex flex-col">
                        <ChartContainer config={chartConfig}>
                            <LineChart
                                data={processedSalesData}
                                margin={{
                                    left: 12,
                                    right: 12,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="date"
                                    tickLine={false}
                                    axisLine={false}
                                    tickMargin={8}
                                    tickFormatter={(value) => new Date(value).toLocaleDateString()}
                                />
                                <ChartTooltip cursor={false} content={<ChartTooltipContent />} />
                                {salesData?.map((user, index) => (
                                    <Line
                                        key={user.userName}
                                        dataKey={user.userName}
                                        type="linear"
                                        stroke={generatePastelColor(index)}
                                        strokeWidth={2}
                                        dot={false}
                                    />
                                ))}
                            </LineChart>
                        </ChartContainer>
                    </CardContent>
                    <CardFooter className="flex-col items-start gap-2 text-sm">
                        <div className="flex gap-2 font-medium leading-none">
                            Total Revenue: {formatPrice(totalRevenue)} <TrendingUp className="h-4 w-4" />
                        </div>
                        <div className="leading-none text-muted-foreground">
                            Showing daily ticket sales for each referrer
                        </div>
                    </CardFooter>
                </Card>

                <Card className="flex flex-col">
                    <CardHeader>
                        <CardTitle>Ticket Distribution</CardTitle>
                        <CardDescription>By Referrer</CardDescription>
                    </CardHeader>
                    <CardContent className="flex-1 pb-0">
                        <ChartContainer
                            config={chartConfig}
                            className="mx-auto aspect-square max-h-[250px] pb-0 [&_.recharts-pie-label-text]:fill-foreground"
                        >
                            <PieChart>
                                <ChartTooltip content={<ChartTooltipContent />} />
                                <Pie data={pieChartData} dataKey="value" nameKey="name" label />
                            </PieChart>
                        </ChartContainer>
                    </CardContent>
                    <CardFooter className="flex-col gap-2 text-sm">
                        <div className="flex items-center gap-2 font-medium leading-none">
                            Total Tickets: {totalTickets}
                        </div>
                        <div className="leading-none text-muted-foreground">
                            Showing distribution of tickets sold by each referrer
                        </div>
                    </CardFooter>
                </Card>

                <Card>
                    <CardHeader>
                        <CardTitle>Monthly Ticket Sales</CardTitle>
                        <CardDescription>
                            {monthlyChartData[0]?.month} - {monthlyChartData[monthlyChartData.length - 1]?.month}
                        </CardDescription>
                    </CardHeader>
                    <CardContent className="flex flex-col">
                        <ChartContainer config={chartConfig}>
                            <BarChart
                                data={monthlyChartData}
                                margin={{
                                    top: 20,
                                    right: 10,
                                    left: 10,
                                    bottom: 20,
                                }}
                            >
                                <CartesianGrid vertical={false} />
                                <XAxis
                                    dataKey="month"
                                    tickLine={false}
                                    tickMargin={10}
                                    axisLine={false}
                                    tickFormatter={(value) => value.split(' ')[0]}
                                />
                                <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
                                <Bar dataKey="tickets" fill="var(--chart-1)" radius={4}>
                                    <LabelList
                                        dataKey="tickets"
                                        position="top"
                                        offset={10}
                                        className="fill-foreground"
                                        fontSize={12}
                                    />
                                </Bar>
                            </BarChart>
                        </ChartContainer>
                    </CardContent>
                    <CardFooter className="flex-col items-start gap-2 text-sm">
                        <div className="flex gap-2 font-medium leading-none">
                            Total Tickets: {totalTickets} <TrendingUp className="h-4 w-4" />
                        </div>
                        <div className="leading-none text-muted-foreground">
                            Showing monthly ticket sales distribution
                        </div>
                    </CardFooter>
                </Card>
            </div>

            <Card>
                <CardHeader>
                    <CardTitle>Referral Statistics</CardTitle>
                </CardHeader>
                <CardContent>
                    <DataTable
                        columns={columns}
                        data={tableData}
                        pagination={pagination}
                        setPagination={setPagination}
                        columnFilters={columnFilters}
                        setColumnFilters={setColumnFilters}
                        pageCount={1}
                    />
                </CardContent>
            </Card>
        </div>
    );
};

export default ReferralDetailPage;
